import { defineAsyncComponent } from 'vue';
import { SeamlessRouterOptions } from '@dh-io-emh/shared/src/router/types';
import { buildSeamlessRoute } from '@dh-io-emh/shared/src/utils/aem.util';
import { defineAEMCustomElement } from '@dh-io-emh/shared/src/utils/defineAEMCustomElement.util';

interface Payload {
  srpLink: string;
}

const TAG_NAME = 'emh-search-filter';

const routerOptions = (payload: Payload): SeamlessRouterOptions => ({
  routes: [
    {
      name: 'srp',
      link: buildSeamlessRoute({ link: payload.srpLink }),
    },
  ],
});

defineAEMCustomElement({
  componentTag: TAG_NAME,
  MainComponent: defineAsyncComponent(() => import('./App.vue')),
  LoadingComponent: defineAsyncComponent(
    () => import('@dh-io-emh/shared/src/components/loading/loadingSpinner/index.vue'),
  ),
  routerOptions,
});
